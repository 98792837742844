export const requiredValidator = (value: string) => !!value || 'Обязательное поле';
export const createMinLenValidator = function (minLen: number) {
  return (v: string) => v.length >= minLen || `Минимальное количество символов: ${minLen}`;
};
export const emailValidator = (v: string) => /.+@.+/.test(v) || 'Некорректный формат e-mail';
export const phoneNumberValidator = (v: string) => /^[0-9+()-]{5,}$/.test(v.replace(' ', '')) || 'Некорректный формат телефона';
export const phoneNumberOrEmptyValidator = (v: string) => {
  if (v.trim().length === 0) {
    return true;
  }
  return phoneNumberValidator(v);
};

export function createDateRuFormatValidator({ minYear }: { minYear: number | undefined }) {
  return function (v: string) {
    const m = v.match(/^(\d\d)\.(\d\d).(\d\d\d\d)$/);
    if (!m) {
      return 'Неверный формат даты. Укажите дату в формате – дд.мм.гггг';
    }
    const errors = [];
    if (Number(m[1]) > 31) {
      errors.push('Диапазон значений дня от 1 до 31.');
    }
    if (Number(m[2]) > 12) {
      errors.push('Диапазон значений месяца от 1 до 12.');
    }
    if (minYear !== undefined && Number(m[3]) < minYear) {
      errors.push(`Минимальное значение года – ${minYear}`);
    }
    return errors.length === 0 || errors.join(' ');
  };
}

export function createNumberMoreThenValidator(moreThen: number) {
  return (v: string) => Number(v) > moreThen || `Должно быть число больше ${moreThen}`;
}

export function timeFormatValidator(v: string) {
  const m = v?.match(/^(\d\d)?:(\d\d?)$/);
  if (!m) {
    return 'Неверный формат времени. Укажите время в формате – чч:мм';
  }

  const errors = [];
  if (Number(m[1]) > 24) {
    errors.push('Диапазон значений часа от 0 до 24.');
  }
  if (Number(m[2]) > 60) {
    errors.push('Диапазон значений минут от 0 до 60.');
  }
  return errors.length === 0
    ? true
    : errors.join(' ');
}
