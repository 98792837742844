























































import {
  Component, Prop, VModel, Vue, Watch,
} from 'vue-property-decorator';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { createDateRuFormatValidator, requiredValidator } from '@/services/defaultFormValidators';

dayjs.extend(customParseFormat);

@Component({})
export default class DateTextField extends Vue {
  @VModel({
    type: String,
    default: '',
  })
  dateValue!: string;

  @Prop({
    type: String,
    default: '',
  })
  label!: string;

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  minYear!: number;

  dateValueFormatted: string = '';
  isDateValuePickerShown: boolean = false;

  rules: Record<string, ((v: string) => true | string)[]> = {
    date: [
      requiredValidator,
    ],
  };

  mounted() {
    if (this.minYear) {
      this.rules.date.push(createDateRuFormatValidator({ minYear: this.minYear }));
    }
  }

  @Watch('dateValue', {
    immediate: true,
  })
  watchDateValueChanged(val: string) {
    const djs = dayjs(val, 'YYYY-MM-DD');
    this.dateValueFormatted = djs.isValid() ? djs.format('DD.MM.YYYY') : '';
  }

  handleDateUpdate() {
    const djs = dayjs(this.dateValueFormatted, 'DD.MM.YYYY');
    if (djs.isValid()) {
      this.dateValue = djs.format('YYYY-MM-DD');
    }
  }
}
