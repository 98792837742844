



































































































































































import { Polygon } from '@/models/entities/Polygon';
import { ApiFacade } from '@/services/ApiFacade';
import { AppStore } from '@/store/types/AppStore';
import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { Cell } from '@/models/entities/Cell';
import { PolygonStatus } from '@/types/Statuses';
import DateTextField from '@/components/DateTextField.vue';
import TimeTextField from '@/components/TimeTextField.vue';
import dayjs from 'dayjs';
import { DateAsString, TimeAsString } from '@/types/CommonTypes';
import { NotificationsService } from '@/services/NotificationService';

interface VMenu extends Vue {
  save(value: string | undefined): void;
}

interface PolygonHomeView {
  $refs: {
    menuStartTime: VMenu;
    menuFinishTime: VMenu;
  };
}

@Component({
  components: {
    TimeTextField,
    DateTextField,
  },
})
class PolygonHomeView extends Vue {
  @Inject() readonly apiFacade!: ApiFacade;
  @Inject() appStore!: AppStore;
  @InjectReactive() polygon!: Polygon | null;
  @Inject() notify!: NotificationsService;
  private polygonLocal: Polygon = new Polygon();
  private isLoading = false;

  private expirationDateSelect: DateAsString = '';
  private startTimeSelect: TimeAsString = '';
  private finishTimeSelect: TimeAsString = '';

  private dialogDelete = false;
  private deletedCells: Cell[] = [];

  handleClick() {
    this.expirationDateSelect = `${Math.random() * 10000}`;
  }

  get isNew() {
    return this.$route.name === 'admin-add-polygon';
  }

  get isSuperAdmin() {
    return this.appStore.getters.profile?.isSuperAdmin;
  }

  get saveButtonName() {
    return this.$route.name === 'admin-add-polygon' ? 'Создать полигон' : 'Сохранить';
  }

  get cellFeatures() {
    return this.appStore.getters.cellFeatures;
  }

  @Watch('polygon')
  async fetchPolygon() {
    if (this.polygon) {
      const polygonLocal = await this.apiFacade.fetchPolygonInfo(this.polygon.polygonId);
      this.refreshPolygonLocal(polygonLocal);
    } else {
      this.polygonLocal.status = PolygonStatus.active;
    }
  }

  async mounted() {
    await this.fetchPolygon();
  }

  AddCell() {
    const cell = new Cell();
    cell.cellFeaturesIds = this.cellFeatures.map((cf) => cf['@id']);
    if (this.polygonLocal.cells.length) {
      cell.cellId = Math.max(...this.polygonLocal.cells.map((c) => {
        return c.cellId;
      })) + 1;
      cell.sequentialNumber = Math.max(...this.polygonLocal.cells.map((c) => {
        return c.sequentialNumber || 0;
      })) + 1;
    } else {
      cell.cellId = 1;
      cell.sequentialNumber = 1;
    }
    this.polygonLocal.cells.push(cell);
  }

  RemoveCell(cell: Cell) {
    const i = this.polygonLocal.cells.indexOf(cell);
    this.deletedCells.push(this.polygonLocal.cells.splice(i, 1)[0]);
    this.polygonLocal.cells.forEach((c, idx) => {
      // eslint-disable-next-line no-param-reassign
      c.sequentialNumber = idx + 1;
    });
  }

  // syncStartTime() {
  //   // this.$refs.menuStartTime.save(this.startTimeSelect);
  // }
  //
  // syncFinishTime() {
  //   // this.$refs.menuFinishTime.save(this.finishTimeSelect);
  // }

  async save() {
    this.polygonLocal = Object.assign(this.polygonLocal, Polygon.mkDateTimeFields(
      this.expirationDateSelect,
      dayjs(this.startTimeSelect, 'HH:mm')
        .toISOString(),
      dayjs(this.finishTimeSelect, 'HH:mm')
        .toISOString(),
    ));

    try {
      if (this.isNew) {
        await this.createPolygon();
        await this.notify.addNotification({ type: 'success', text: 'Полигон успешно добавлен' });
      } else {
        await this.updatePolygon();
        await this.notify.addNotification({ type: 'success', text: 'Полигон успешно обновлён' });
      }
    } catch (e) {
      await this.notify.addNotification({ type: 'error', text: 'Ошибка!' });
    }
  }

  async createPolygon() {
    const polygon = await this.apiFacade.createPolygon(this.polygonLocal);
    this.$router.push({
      name: 'polygon',
      params: { id: String(polygon.polygonId) },
    });
  }

  async updatePolygon() {
    // удалим удалённые ячейки
    await Promise.all(this.deletedCells.map(async (c) => {
      await this.apiFacade.deleteCell(c);
    }));
    this.deletedCells = [];

    // изменим или добавим новые
    await Promise.all(this.polygonLocal.cells.map(async (c) => {
      if (c['@id']) {
        await this.apiFacade.updateCell(c);
      } else {
        const cc = c;
        cc.polygon = this.polygonLocal['@id'];
        await this.apiFacade.createCell(cc);
      }
    }));

    const polygon = await this.apiFacade.updatePolygon(this.polygonLocal);
    this.refreshPolygonLocal(polygon);
    this.$emit('polygonUpdated', polygon);
  }

  refreshPolygonLocal(polygon: Polygon) {
    this.polygonLocal = polygon;

    this.expirationDateSelect = this.polygonLocal.expirationDateISO;
    this.startTimeSelect = this.polygonLocal.startTime || '';
    this.finishTimeSelect = this.polygonLocal.finishTime || '';
  }

  deletePolygon() {
    this.dialogDelete = true;
  }

  closePolygonDelete() {
    this.dialogDelete = false;
  }

  async deletePolygonConfirm() {
    await this.apiFacade.deletePolygon(this.polygonLocal);
    this.dialogDelete = false;
    await this.notify.addNotification({ type: 'success', text: 'Полигон удалён' });
    this.$router.push({ name: 'polygons' });
  }
}

export default PolygonHomeView;
