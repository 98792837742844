





















































import {
  Component, VModel, Prop, Vue,
} from 'vue-property-decorator';
import { requiredValidator, timeFormatValidator } from '@/services/defaultFormValidators';

@Component({})
export default class TimeTextField extends Vue {
  @VModel()
  timeValue!: string;

  @Prop({
    type: String,
    default: '',
  })
  label!: string;

  isTimeValuePickerShown: boolean = false;

  rules = {
    time: [
      requiredValidator,
      timeFormatValidator,
    ],
  };
}
